/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

// Libraries
import parse from 'html-react-parser'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Requirements from 'components/Requirements'
import ParseContent from 'components/shared/ParseContent'
import Review from 'components/Review'
import Sirelo from 'components/Sirelo'
import PriceIndication from 'components/PriceIndication'
import Blog from 'components/Blog'
import Tabs from 'components/Tabs'
import MobileTabs from 'components/MobileTabs'
import RatingAlt from 'components/Reviews/RatingAlt'


// Elements
import HeroDefault from 'components/elements/HeroDefault'
import ImageBackground from 'components/elements/ImageBackground'
import HeroDescription from 'components/elements/HeroDescription'
import BlockDefault from 'components/elements/BlockDefault'
import ButtonDefault from 'components/elements/ButtonDefault'
import ButtonSecondary from 'components/elements/ButtonSecondary'
import BackgroundDescription from 'components/elements/BackgroundDescription'

// Data
import blogInfo from 'src/graphql/blogInfo'
import Infographic from '../components/Infopgrahic/Infographic'


const Intro = styled(BlockDefault)`
  @media(min-width: 992px) {
    margin-top: -150px;

    & h2 {
      font-size: ${props => props.theme.font.size.xl};
      font-weight: ${props => props.theme.font.weight.s};

      & strong {
        font-weight: ${props => props.theme.font.weight.xl};
      }
    }
  }

  @media(max-width: 991px) {
    margin-top: -100px;
  }
`

const FeaturedImage = styled(ImageBackground)`
  padding-top: 50%;
  border-radius: 35px;
  overflow: hidden;
`

const Block = styled(BlockDefault)`
  @media (min-width: 992px) {
    margin-top: -200px;
  }

  @media (max-width: 991px) {
    margin-top: -50px;
  }

  @media (max-width: 575px) {
    margin-top: -35px;
  }
`

const Content = styled(ParseContent)`
  & h1, & h2, & h3, & h4, & h5 {
    color: ${props => props.theme.color.text.contrast};
  }
`

const HeroBlock = styled(BlockDefault)`
  & h1 {
    font-size: ${props => props.theme.font.size['50']};
    line-height: 50px;
    color: ${props => props.theme.color.text.contrast};
    text-shadow: none;
  }

  & p {
    color: ${props => props.theme.color.text.main};
  }

  & span {
    color: ${props => props.theme.color.text.main};
    display: block;
  }

  margin-top: 200px;
`

const HeroTitle = styled(ParseContent)`
  @media (max-width: 991px){
    & h1 {
      font-size: ${props => props.theme.font.size['35']};
      line-height: 40px;
    }
  }
`

const CTA = styled(ParseContent)``

const ButtonSecond = styled(ButtonSecondary)`
  width: 295px;
  text-align: left;

  & a {
    font-size: ${props => props.theme.font.size['18']};
  }
`

const ButtonFirst = styled(ButtonDefault)`
  width: 295px;

  & a {
    font-size: ${props => props.theme.font.size['18']};
  }
`

const ContentTitle = styled(ParseContent)`
  & h2 {
    font-size: ${props => props.theme.font.size['50']};
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path
      
      acf {
        banner {
          title
          description
          cta {
            title
            buttonOne: button_one {
              title
              url
            }
            buttonTwo: button_two {
              title
              url
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        intro {
          title
        }

        tabs {
          title
          tab_name
          description
          cta {
            title
            button {
              title
              url
            }
          }
        }

        terms {
          title
          usp {
            relation
          }
        }

        intro {
          background_description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          description
        }

        review {
          title
          rating
          description
          background_description
        }

        outro {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          description
        }

        blog {
          related
          background_description
        }

        show_infographic
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <section>
        <HeroDefault rounded image={acf.banner.image} big custom overflow>
          <HeroDescription className="d-flex align-items-center justify-content-start h-100">
            <HeroBlock bigBorderRadius className="col-lg-6">
              <HeroTitle content={acf.banner.title} className="mb-4" />
              <ParseContent content={acf.banner.description} className="mb-4" />
              <CTA content={acf.banner.cta.title} />
              <div className="px-lg-5 d-flex flex-wrap justify-content-center text-center">
                <div>
                  <ButtonFirst type="next" to={acf.banner.cta.buttonOne.url}>{acf.banner.cta.buttonOne.title}</ButtonFirst>
                  <span className="my-2">of</span>
                </div>
                <ButtonSecond to={acf.banner.cta.buttonTwo.url}>{acf.banner.cta.buttonTwo.title}</ButtonSecond>
              </div>
            </HeroBlock>
          </HeroDescription>
        </HeroDefault>
      </section>

      <section id="tabInfo" className="pt-5 pt-lg-0">
        <div className="container py-5 ">
          <div className="row pt-5 pb-0 pb-lg-5 mt-5 mt-lg-0">
            <div className="col-12 d-flex justify-content-end px-lg-0">
              <ContentTitle content={acf.intro.title} />
            </div>
          </div>
          <Tabs className="d-none d-lg-flex" fields={acf.tabs} />
          <MobileTabs className="d-lg-none" content={acf.tabs} />
        </div>
      </section>

      <section>
        <div className="container mt-5 py-5">
          <div>
            {parse(acf.terms.title)}
          </div>
          <div className="mt-5">
            <Requirements ids={acf.terms.usp.map(({ relation }) => relation)} />
          </div>
        </div>
      </section>

      {acf.review && acf.review.title && (
        <section className="pt-5">
          <div className="container">
            <div className="row flex-wrap-reverse">
              <div className="col-lg-8">
                <BackgroundDescription className="mt-3" content={acf.review.background_description} relative />
              </div>
              <div className="col-lg-4">
                <PriceIndication noInfo />
              </div>
            </div>
          </div>
          <Review fields={acf.review} />
        </section>
      )}

      <section>
        <div className="container mb-lg-5 py-5">
          <div className="row">
            <div className="col-lg-7" />
            <div className="col-lg-5">
              <RatingAlt noTitle className="pb-lg-0 pb-4" />
              <div className="mt-4">
                <PriceIndication />
              </div>
            </div>
          </div>
        </div>
      </section>

      {acf.show_infographic && (
        <Infographic />
      )}

      {(acf.blog && acf.blog.related && acf.blog.related.length > 0) && (
        <section>
          <div className={`${acf.show_infographic && 'mt-lg-5 pt-lg-5'} container mb-lg-5 pb-5`}>
            {acf.blog.background_description && (
              <BackgroundDescription content={acf.blog.background_description} relative />
            )}
            <Blog ids={acf.blog.related} fields={blogInfo.get()} hideMore hideFilters />
          </div>
        </section>
      )}
    </Layout>
  )
}

export default PageTemplate
