import React, { useState } from 'react'

// Images
import Arrow from 'img/icon/arrow-right-circled.inline.svg'
import DownArrow from 'img/icon/arrow-down-circled.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'
import { motion, useAnimation } from 'framer-motion'


const DownIcon = styled(DownArrow)`
  height: auto;
  width: 40px;
`

const Content = styled.div`
  padding: 40px 20px;

  h1, h2, h3,h4 {
    font-weight: ${props => props.theme.font.weight.xl};
    color: ${props => props.theme.color.text.contrast};
    font-size: ${props => props.theme.font.size.xm};

  }

  h2 {
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size.ml};
    color: ${props => props.theme.color.text.contrast};

    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }

  p {
    font-size: ${props => props.theme.font.size.sm};
    width: 75%;

    @media screen and (max-width: 991px) {
      width: auto;
      text-align: left;
    }

    strong {
      font-weight: ${props => props.theme.font.weight.xl};
      font-size: ${props => props.theme.font.size.m};
      color: ${props => props.theme.color.text.secondary};
    }

    .list-item {
      &:first-of-type {
        padding-top: 20px !important;
      }
      &:last-of-type {
        padding-bottom: 20px !important;
      }
    }

    span {
      font-size: ${props => props.theme.font.size.m};
    }
  }
`

const StyledTabContent = styled.div``

const TabContainer = styled.div`
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
`

const ContentContainer = styled(motion.div)`
  background-color: ${props => props.theme.color.light};
  /* padding: 40px 40px 40px 40px; */

  .has-border + .has-border {
    border-left: 1px solid ${props => props.theme.color.light}; 
  }
`

const MobileTabs = ({ content, className }) => {

  const [currentTab, setCurrentTab] = useState(0)

  return (
    <StyledTabContent className={`container ${className ? `${className}` : ``}`}>
      <TabContainer className="row flex-wrap mx-0">
        {content.map((c, index) => (
          <Tab c={c} setCurrentTab={setCurrentTab} currentTab={currentTab} index={index} selected={index === currentTab} />
        ))}
      </TabContainer>
    </StyledTabContent>
  )
}

export default MobileTabs

const StyledTab = styled.div`
  ${props => props.selected ? css`
  background-color: ${props => props.theme.color.contrast};
    color: ${props => props.theme.color.text.light}; 
  ` : css`
    background-color: ${props => props.theme.color.contrast};
    color: ${props => props.theme.color.text.light}; 
  `}

  cursor: pointer;
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.l};
  padding: 20px 20px 20px 20px;

  @media (max-width: 991px){
    & h3 {
      width: 90%;
      margin-bottom: 0;
    }
  }

  &.is-active {
    & h3 {
      font-weight: ${props => props.theme.font.weight.xl};
    }
  }
`

const Tab = ({ c, selected, setCurrentTab, currentTab, index }) => {

  const controls = useAnimation()

  if (currentTab === index) {
    controls.start("open")
  } else {
    controls.start("closed")
  }

  return (
    <>
      <StyledTab onClick={() => setCurrentTab(index)} selected={selected} className={`${index === currentTab && `is-active`} col-12 d-flex align-items-center`}>
        <h3>{parse(c.tab_name)}</h3>
        <DownIcon />
      </StyledTab>
      <ContentContainer
        className="col-12"
        animate={controls}
        variants={{
          open: { height: 'auto', transition: { type: 'spring', damping: 20 } },
          closed: { height: '0px', transition: { type: 'spring', damping: 20 } }
        }}
      >
        <motion.div
          animate={controls}
          variants={{
            open: { opacity: 1, transition: { delay: 0.3 } },
            closed: { opacity: 0 }
          }}
        >
          <Content>
            {c.description && (
              <>
                {parse(c.title)}
                {parse(c.description)}
              </>
            )}
          </Content>
        </motion.div>
      </ContentContainer>
    </>
  )
}