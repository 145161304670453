import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Elements
import BlockDefault from 'components/elements/BlockDefault'
import ParseContent from 'components/shared/ParseContent'
import ButtonDefault from 'components/elements/ButtonDefault'

// Icons
import Arrow from 'img/icon/arrow-right-circled.inline.svg'
import DownArrow from 'img/icon/arrow-down-circled.inline.svg'

const TabItem = styled(motion.button)`
  background-color: ${props => props.theme.color.contrast};
  text-align: left;
  color: ${props => props.theme.color.text.light};
  font-size: ${props => props.theme.font.size.xl};
  line-height: 30px;
  border-radius: 27px;
  min-height: 105px;
  font-weight: ${props => props.isActive ? "800" : "400"} !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  & span {
    width: 254px;
  }

  &.is-active {
    font-weight: bold !important;
  }
`
const TabColumn = styled.div`
  z-index: 9;
  height: 100%;
  overflow: hidden;
`

const Icon = styled(Arrow)`
  height: 40px;
  width: 40px;
`

const DownIcon = styled(DownArrow)`
  height: 40px;
  width: 40px;
`


const StyledContent = styled(ParseContent)`
  & h3 {
    color: ${props => props.theme.color.text.contrast};
    font-size: ${props => props.theme.font.size.xl};
  }
  
  @media (max-width: 991px){
    h3,h4 {
      font-size: ${props => props.theme.font.size.xm};
    }
  }

  & h4 {
    color: ${props => props.theme.color.text.contrast};
    margin-bottom: 0;
  }
`

const StyledBlockDefault = styled(BlockDefault)`
  z-index: 8;
  @media (min-width: 992px){
    padding-left: 60px !important;
  }
`

const StyledDiv = styled.div`
  @media (max-width: 991px){
    padding: 16px 10px !important;
}
`

const TabLink = styled.a`
  transition: all 3s;
  transform: none;
  color: ${props => props.theme.color.text.light} !important;
`

function Tabs({ fields, className }) {

  const [currentTab, setCurrentTab] = useState(0)

  return (
    <div className={`row ${className ? `${className}` : ``}`}>
      <TabColumn className="col-lg-4 mr-n5 pt-4 pt-lg-5">
        {fields.map((tab, i) => (
          <TabItem
            key={tab}
            whileHover={{ fontWeight: "800" }}
            transition={{ duration: 0.1 }}
            onClick={() => setCurrentTab(i)}
            selected={i === currentTab} c
            className={`${i === currentTab && `is-active`} w-100 mb-3 px-4`}
          >
            <div className="d-none d-lg-flex justify-content-between align-items-center">
              <span className="ml-2">{tab.tab_name}</span>
              <Icon className="d-none d-lg-block" />
            </div>
          </TabItem>
        ))}
      </TabColumn>
      <div className="col-lg-8 px-lg-0">
        {fields[currentTab].description && (
          <StyledDiv>
            <StyledBlockDefault bigBorderRadius >
              <StyledContent content={fields[currentTab].title} />
              <StyledContent content={fields[currentTab].description} />
              <div className="mt-5 pt-lg-3 d-flex flex-wrap align-items-center justify-content-between">
                <ParseContent content={fields[currentTab].cta.title} />
                <ButtonDefault type="next" to={fields[currentTab].cta.button.url}>{fields[currentTab].cta.button.title}</ButtonDefault>
              </div>
            </StyledBlockDefault>
          </StyledDiv>
        )}
      </div>
    </div>
  )
}

export default Tabs
